import shopifyEnterpriseGenericDefaults from '../shopify-enterprise-generic/config.js';

export default {
  includes: ['shopify-enterprise-generic'],
  ...shopifyEnterpriseGenericDefaults,
  Widgets: [
    ...shopifyEnterpriseGenericDefaults.Widgets,
    {
      name: 'SearchVehicleWidgetGarage',
      type: 'Garage',
    },
  ],
};
